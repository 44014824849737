@import '@scss/functions';

:root {
  --primary: #F0E2E2;
  --black: #050505;
}

* {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 18px;
	vertical-align: baseline;
	list-style: none;
  text-decoration: none;
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--primary);
}

body {
  color: var(--black);
}

.App {
  scroll-snap-type: y proximity;
  overflow: auto;
  scroll-behavior: smooth;

  @media (min-height: 760px) {
    height: 100vh;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 700;
}