@import '@scss/functions';

.section__prijzen {
  background-color: white;

  // @include border(white, bottom-right);
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .section__inner {
    margin: 0 auto;
    width: 100%;

    .section__title {
      text-align: center;
      margin: s(3) 0;
    }

    .section-prices {
      max-width: 420px;
      margin: 0 auto;

      &:has(.price-item:hover) {
        .price-category:not(:hover) {
          opacity: .5;
        }
        .price-item:not(:hover) {
          opacity: .5;
        }
      }

      .price-category {
        transition: opacity .2s ease;

        .price-item {
          transition: opacity .2s ease;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    h3 {
      margin-top: s(2);
    }
  } 
}